var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterInventory',{attrs:{"catalogo":'catalogo'},on:{"filtersItem":_vm.filtersItem}})],1),_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Productos ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1)],1)]},proxy:true},{key:"item.gallery",fn:function(ref){
var item = ref.item;
return [(item.gallery!=undefined)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.viewGallery(item.images)}}},[_vm._v("mdi-image-multiple")]):_vm._e(),_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.dialogGallery),callback:function ($$v) {_vm.dialogGallery=$$v},expression:"dialogGallery"}},[_c('v-card',[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"space-around"}},_vm._l((_vm.imagesGallery),function(image,n){return _c('v-col',{key:n,staticClass:"d-flex child-flex pa-0",staticStyle:{"border":"solid 1px #b7b7b7","border-collapse":"collapse"},attrs:{"cols":"3"}},[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":_vm.liga + 'files/items/'+image,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('a',{staticStyle:{"color":"transparent!important","margin-left":"10px"},attrs:{"href":_vm.liga + 'files/items/'+image,"download":image,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")])],1)])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("Cerrar")])],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0px!important"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 pa-5",staticStyle:{"background":"#f7f7f7"},attrs:{"headers":_vm.headers2,"items":_vm.variations(item.id),"dense":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [(item.is_published==true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-record")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-record")])]}},{key:"item.unit_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.unit(item.unit_id))+" ")]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen productos registrados. ")])],2)],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [(item.product_type!='Variable')?_c('div',[(item.is_published==true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-record")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-record")])],1):_vm._e()]}},{key:"item.unit_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.unit(item.unit_id))+" ")]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen productos registrados. ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }